import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import ContactForm from '../../components/PageFragments/ContactForm';
import SEO from '../../components/Seo';
import Checkout from '../../components/Checkout';

const IndexPage = () => {
  const [starsCount, setStarsCount] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStarsCount(resultData.stargazers_count)
      }) // set data for the number of stars
  }, [])
  return (
    <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SEO
        title="Blog"
        description="I like blogging about various web technologies and other stuff related to
          javascript and other trends like graphql, prisma etc. This blog expresses my views of various technologies
          and scenarios I have come across in realtime."
        path="blog"
      />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Math</h1>
        </div>
    <section>
      <p>Runtime Data: Star count for the Gatsby repo {starsCount}</p>
    </section>
    <section>
<a class="gumroad-button" href="https://gum.co/DZVDW" target="_blank">Buy this worksheet</a>
      <Checkout />
    </section>
      </SidebarWrapper>
    </Layout>
  </Layout>
  )
}

export default IndexPage;